import {Container, Row} from "react-bootstrap";

// Custom Component
import Navbar from "../navbar";
import Footer from "../footer";

const Layout = (props) => {
    return (
        <div className="App">
            <div className="d-flex flex-grow-1">
                <Container fluid>
                    <Navbar />
                    <Row>{props.children}</Row>
                </Container>
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
