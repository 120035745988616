import {configureStore} from "@reduxjs/toolkit";

// Slice
import userReducer from "./userSlice";

export default configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: {
                warnAfter: 128,
            },
            serializableCheck: {
                warnAfter: 128,
            },
        }),
    reducer: {
        user: userReducer,
    },
});
