import {Link} from "react-router-dom";
import {Logo} from "../../../assets/images";

const Footer = () => {
    return (
        <footer className="mt-auto bg-footer">
            <div className="d-flex flex-column flex-md-row mt- py-3 container align-items-center align-items-md-start">
                <img className="mb-3 mb-md-0" src={Logo} width={100} height={30} alt="Footerlogo" />
                <div className="px-5 text-center text-md-start mb-3 mb-md-0">
                    <p className="fw-bold fs-16 text-light">Support</p>
                    <p className="fs-12 text-light fw-bold">
                        Athletes For Athletes Solutions Sdn Bhd
                    </p>
                    <p className="fs-12 text-light">
                        Pjs 5, Lot 3, Jalan 51/203, Seksyen 51,
                        <br />
                        46050 Petaling Jaya, Selangor.
                    </p>
                    <p className="fs-12 text-light">hello@afa.community</p>
                    <p className="fs-12 text-light">+6 016-2930845</p>
                </div>
                {/* <div className="px-5 text-center text-md-start mb-3 mb-md-0">
                    <p className="fw-bold fs-16 text-light">Account</p>
                    <p className="fs-12 text-light">My Account</p>
                    <p className="fs-12 text-light">Login / Register</p>
                    <p className="fs-12 text-light">Cart</p>
                    <p className="fs-12 text-light">Wishlist</p>
                    <p className="fs-12 text-light">Shop</p>
                </div> */}
                <div className="px-5 text-center text-md-start">
                    <p className="fw-bold fs-16 text-light">Quick Link</p>
                    <Link className="text-decoration-none" to="/privacy-policy">
                        <p className="fs-12 text-light">Privacy Policy</p>
                    </Link>
                    <Link className="text-decoration-none" to="/terms-condition">
                        <p className="fs-12 text-light">Terms Of Use</p>
                    </Link>

                    {/* <p className="fs-12 text-light">FAQ</p> */}
                    {/* <p className="fs-12 text-light">Contact</p> */}
                </div>
            </div>
            <hr className="text-muted" />
            <p className="fs-12 text-center text-muted">
                Copyright &#169; {new Date()?.getFullYear()} AFA Community Sdn. Bhd. (1488118-H)
            </p>
        </footer>
    );
};

export default Footer;
