import React, {useEffect, lazy, Suspense} from "react";
import {Route, Routes, Navigate, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

// Utility Service
import {lazyRetry} from "./utils";

// Scroll Component
import ScrollToTop from "./components/common/scrolltotop";

// Auth-Service
import PrivateRoute from "./utils/privateroute";

// Redux-Functions
import {selectToken} from "./redux/userSlice";
import PrivacyAndPolicy from "./pages/content/privacyandpolicy";
import TermsAndCondition from "./pages/content/termsandcondition";

// Public Pages
const Home = lazy(() => lazyRetry(() => import("./pages/home")));
const Complex = lazy(() => lazyRetry(() => import("./pages/complex")));
const ViewAll = lazy(() => lazyRetry(() => import("./pages/viewall")));
const FourZeroFour = lazy(() => lazyRetry(() => import("./pages/404")));

// Authentication Pages
const Login = lazy(() => lazyRetry(() => import("./pages/authentication/login")));
const Register = lazy(() => lazyRetry(() => import("./pages/authentication/register")));
const ForgetPassword = lazy(() => lazyRetry(() => import("./pages/authentication/forgetpassword")));
const ResetPassword = lazy(() => lazyRetry(() => import("./pages/authentication/resetpassword")));

function App() {
    const location = useLocation();

    const token = useSelector(selectToken);

    // Service Worker
    useEffect(() => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.ready.then((registration) => {
                registration.update();
            });
        }
    }, [location]);

    return (
        <>
            <ScrollToTop />
            <Suspense>
                <Routes>
                    {/* Home Page Route */}
                    <Route path="/" element={<Home />} />
                    {/* Complex Page Route */}
                    <Route path="/complex/:slug" element={<Complex />} />
                    <Route path="/view-all" element={<ViewAll />} />
                    <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
                    <Route path="/terms-condition" element={<TermsAndCondition />} />

                    {/* Authentication Page Route */}
                    {/* Login Page Route */}
                    <Route path="/login" element={<Login />} />
                    {/* Register Page Route */}
                    <Route path="/register" element={<Register />} />
                    {/* Forget Password Page Route */}
                    <Route path="/forget-password" element={<ForgetPassword />} />
                    {/* Reset Password Page Route */}
                    <Route path="/reset-password" element={<ResetPassword />} />

                    {/* Private Route */}
                    <Route element={<PrivateRoute token={token} />}></Route>

                    {/* 404 Page Route */}
                    <Route path="/404" element={<FourZeroFour />} />
                    {/* Error Page Route */}
                    <Route path="*" element={<Navigate to="/404" replace />} />
                </Routes>
            </Suspense>
        </>
    );
}

export default App;
