import axios from "axios";

const baseUrl = process.env.REACT_APP_API_BASEURL;

/**
 * @function getContentList
 * @param ()
 * @description api to get content List.
 */
export const getContentList = async () => {
    try {
        const result = await axios.get(`${baseUrl}/directory/v1/variable/all`);
        return result;
    } catch (err) {
        return err;
    }
};
