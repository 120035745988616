import {Navigate, Outlet} from "react-router-dom";
import PropTypes from "prop-types";

// handle the private routes
const PrivateRoute = (props) => {
    const token = props?.token;
    if (token) {
        return <Outlet>{props?.children} </Outlet>;
    } else {
        return <Navigate to="/" />;
    }
};

export default PrivateRoute;

PrivateRoute.prototypes = {
    children: PropTypes.node.isRequired,
};
