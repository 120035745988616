import {createSlice} from "@reduxjs/toolkit";

// Utility Service
import {getToken, getUser} from "../utils/authutils";

const initialState = {
    token: getToken(),
    user: getUser(),
};

export const userSlice = createSlice({
    name: "User",
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
    },
});

export const {setToken, setUser} = userSlice.actions;

export const selectToken = (state) => state.user.token;
export const selectUser = (state) => state.user.user;

export default userSlice.reducer;
